import { useState } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { Features } from './components/features';
import { About } from './components/about';
import { Services } from './components/services';
import { Gallery } from './components/gallery';
import { Honor } from './components/honor';
import { Team } from './components/Team';
import { Contact } from './components/contact';
import allData from './data';
import SmoothScroll from 'smooth-scroll';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const isMobile = () => {
  const userAgent = navigator.userAgent;
  return userAgent.match(/(iPhone|iPod|Android|ios|iPad|AppleWebKit.*Mobile.*)/i);
}

const App = () => {
  const [lan, setLan] = useState('zh');

  function handleLanChange() {
    if (lan === 'zh') {
      setLan('en');
    } else {
      setLan('zh');
    }
  }

  function onCloseMenu(e) {
    const expanded = document.getElementById('bs-example-navbar-collapse-1').getAttribute('aria-expanded');
    if (expanded === 'true' && !e.target.className.includes('menu')) {
      document.getElementById('menuButton').click();
    }
  }

  const data = allData[lan];

  return (
    <div onClick={onCloseMenu}>
      <Navigation data={data.Navigation} onLanChange={handleLanChange} />
      <Header data={data.Header} />
      <About data={data.About} />
      <Features data={data.Features} />
      <Services data={data.Services} />
      <Gallery data={data.Gallery} />
      <Team data={data.Team} />
      <Honor data={data.honor} />
      <Contact data={data.Contact} />
    </div>
  );
};

export default App;
