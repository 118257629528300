const data = {
  Navigation: {
    toggleTitle: '导航',
    title: '讯和数聚 DATAJOINT',
    language: 'English',
    list: [
      {
        title: '关于我们',
        href: '#about-title',
      },
      {
        title: '解决方案',
        href: '#services-section-title',
      },
      {
        title: '案例展示',
        href: '#portfolio-section-title',
      },
      {
        title: '团队介绍',
        href: '#team-section-title',
      },
      {
        title: '荣誉资质',
        href: '#honor-section-title',
      },
      {
        title: '联系我们',
        href: '#contact',
      },
    ],
  },
  Header: {
    title: '因为信任，所以简单',
  },
  About: {
    title1: '关于我们',
    title2: '公司理念',
    paragraph:
      '青岛讯和数聚（DATAJOINT）长期致力于提供移动互联网时代和物联网时代的整体解决方案。公司持续为传统企业的客户提供优质的技术开发服务，同时提供基于低代码平台的解决方案。经过团队的奋斗和努力，公司顺利通过了2019年国家高新技术企业的认证，同时荣获2019年青岛市高新区创新型企业、青岛市高新区青年企业家协会优秀会员单位等荣誉称号。',
    list: [
      '初心 --- 快乐工作，为客户创造价值，让社会更高效',
      '使命 --- 技术服务客户',
      '愿景 --- 坚守初心， 长期成功',
      '价值观 --- 诚信、激情、敬业、团队合作、拥抱变化、客户第一',
      '基本方针 --- 以客户为中心，以奋斗者为本',
    ],
  },
  Features: {
    title: '我们的优势',
    list: [
      {
        img: 'img/feature/01.png',
        title: '丰富的跨行业经验',
      },
      {
        img: 'img/feature/02.png',
        title: '专业的软硬件开发团队',
      },
      {
        img: 'img/feature/03.png',
        title: '极致响应的服务',
      },
      {
        img: 'img/feature/04.png',
        title: '多年跨国合作经验',
      },
    ],
  },
  Services: {
    title: '解决方案',
    list: [
      {
        img: 'img/services/01.png',
        name: '数字工厂',
        text: '基于低代码技术及丰富的制造业经验可以为您提供灵活低成本的数字化解决方案',
      },
      {
        img: 'img/services/02.png',
        name: '物联网开发',
        text: '多年物联网行业经验及专业的软硬件开发团队可以为您提供无缝的解决方案',
      },
      {
        img: 'img/services/03.png',
        name: '技术服务',
        text: '为国内外客户提升产品规划设计、软件研发、测试、运维等全栈式的专业技术服务，目前服务客户主要为德国代傲表计、中软国际等知名企业',
      },
    ],
  },
  Gallery: {
    title: '案例展示',
    list: [
      {
        title: 'ERP系统',
        image: 'img/portfolio/03.png',
        text: [
          '好用，系统功能强大',
          '高效，提升您的工作效率',
          '简洁，麻雀虽小五脏俱全',
          '稳定，系统平稳运行的保障',
        ],
      },
      {
        title: '能量预测系统',
        image: 'img/portfolio/01.png',
        text: [
          '好用，系统功能强大',
          '高效，提升您的工作效率',
          '简洁，麻雀虽小五脏俱全',
          '稳定，系统平稳运行的保障',
        ],
      },
      {
        title: '漏损管控系统',
        image: 'img/portfolio/02.png',
        text: [
          '好用，系统功能强大',
          '高效，提升您的工作效率',
          '简洁，麻雀虽小五脏俱全',
          '稳定，系统平稳运行的保障',
        ],
      },
    ],
  },
  Team: {
    title: '团队介绍',
    paragraph: [
      '共同打造一支充满善意、谦虚团结、积极向上的团队',
      '人才标准：诚信乐观、善意奉献、谦虚团结、自律自省',
      '奖优罚劣，奖勤罚懒，绝不让雷锋吃亏',
      '前沿技术和商业分享会',
      '周五读书会，独立思考',
    ],
    list: [
      {
        img: 'img/team/02.jpg',
      },
      {
        img: 'img/team/03.jpg',
      },
      {
        img: 'img/team/04.jpg',
      },
      {
        img: 'img/team/05.jpg',
      },
    ],
  },
  honor: {
    title: '荣誉资质',
    list: [
      {
        img: 'img/honor/01.jpg',
      },
      {
        img: 'img/honor/02.jpg',
      },
    ],
  },
  Contact: {
    name: '青岛讯和数聚信息咨询有限公司',
    address: '青岛市高新区华中路66号基金谷2号楼501',
    contactName: '邵先生',
    phone: '18806485928',
    email: 'contact@datajoint.cn',
    miit: '鲁ICP备18040258号-1',
    recruitment: '招聘JAVA后端、WEB前端，点击投递简历',
  },
};

export default data;
