import { Image } from './image';
import Slider from 'react-slick';
import { useRef, useState } from 'react';

export const Gallery = ({ data: { title, list } }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    beforeChange: (oldValue, newValue) => {
      setSliderIndex(newValue);
    },
  };

  const sliderRef = useRef();
  const [sliderIndex, setSliderIndex] = useState(0);

  const onPrevPress = () => {
    sliderRef.current.slickPrev();
  };

  const onNextPress = () => {
    sliderRef.current.slickNext();
  };

  const renderFooterContent = () => {
    const { title, text } = list[sliderIndex];

    return (
      <div
        style={{
          width: 0,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: 1, display: 'column', textAlign: 'left', }}>
          <span style={{ fontSize: '30px', color: '#333', }}>
            {title}
          </span>

          <ul style={{ color: '#333', listStyleType: 'disc' }}>
            {text.map(i => {
              return <li style={{ fontSize: '18px', lineHeight: 1.8 }}>{i}</li>;
            })}
          </ul>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <button
          type="button"
          className="gallery-button gallery-button-prev"
          onClick={onPrevPress}
        />

        {renderFooterContent()}

        <button
          type="button"
          className="gallery-button gallery-button-next"
          onClick={onNextPress}
        />
      </div>
    );
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div id="portfolio-section-title" className="section-title">
          <h2>{title}</h2>
        </div>

        <div className="row">
          <div className="portfolio-items">
            <Slider ref={sliderRef} {...settings}>
              {list.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                  <Image title={d.title} image={d.image} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {renderFooter()}
      </div>
    </div>
  );
};
