const data = {
  Navigation: {
    toggleTitle: '导航',
    title: '讯和数聚 DATAJOINT',
    language: '中文',
    list: [
      {
        title: 'About us',
        href: '#about-title',
      },
      {
        title: 'Solution',
        href: '#services-section-title',
      },
      {
        title: 'Case',
        href: '#portfolio-section-title',
      },
      {
        title: 'Team',
        href: '#team-section-title',
      },
      {
        title: 'Honor',
        href: '#honor-section-title',
      },
      {
        title: 'Contact',
        href: '#contact',
      },
    ],
  },
  Header: {
    title: 'Trust makes everything simple',
  },
  About: {
    title1: 'About us',
    title2: 'Company philosophy',
    paragraph:
      'DATAJOINT has long been committed to providing overall solutions in the mobile Internet era and the Internet of Things era. The company continues to provide high-quality technology development services to customers of traditional enterprises, as well as solutions based on low-code platforms. After the struggle and hard work of the team, the company successfully passed the certification of the national high-tech enterprise in 2019, and also won the honorary titles such as the innovative enterprise of Qingdao High-tech Zone in 2019 and the outstanding member unit of the Youth Entrepreneurs Association of Qingdao High-tech Zone in 2019.',
    list: [
      'Original intention --- Work happily, create value for customers, and make society more efficient',
      'Mission --- Technical service customers',
      'Vision --- Stick to the original intention, long-term success',
      'Values --- Integrity, Passion, Dedication, Teamwork, Embracing Change, Customer First',
      'Basic policy --- Customer-centric, striver-oriented',
    ],
  },
  Features: {
    title: 'advantage',
    list: [
      {
        img: 'img/feature/01.png',
        title: 'Extensive cross-industry experience',
      },
      {
        img: 'img/feature/02.png',
        title: 'Professional software and hardware development team',
      },
      {
        img: 'img/feature/03.png',
        title: 'Extremely responsive service',
      },
      {
        img: 'img/feature/04.png',
        title: 'Rich experience in cross-border cooperation',
      },
    ],
  },
  Services: {
    title: 'Solution',
    list: [
      {
        img: 'img/services/01.png',
        name: 'digital factory',
        text: 'Based on low-code technology and rich manufacturing experience, we can provide you with flexible and low-cost digital solutions',
      },
      {
        img: 'img/services/02.png',
        name: 'IoT Development',
        text: 'Years of experience in the IoT industry and a professional software and hardware development team can provide you with seamless solutions',
      },
      {
        img: 'img/services/03.png',
        name: 'Technical Services',
        text: 'Provide full-stack professional technical services such as product planning and design, software R&D, testing, operation and maintenance for domestic and foreign customers',
      },
    ],
  },
  Gallery: {
    title: 'Case',
    list: [
      {
        title: 'ERP',
        image: 'img/portfolio/03.png',
        text: [
          '好用，系统功能强大',
          '高效，提升您的工作效率',
          '简洁，麻雀虽小五脏俱全',
          '稳定，系统平稳运行的保障',
        ],
      },
      {
        title: 'Energy prediction',
        image: 'img/portfolio/01.png',
        text: [
          '好用，系统功能强大',
          '高效，提升您的工作效率',
          '简洁，麻雀虽小五脏俱全',
          '稳定，系统平稳运行的保障',
        ],
      },
      {
        title: 'Leakage control',
        image: 'img/portfolio/02.png',
        text: [
          '好用，系统功能强大',
          '高效，提升您的工作效率',
          '简洁，麻雀虽小五脏俱全',
          '稳定，系统平稳运行的保障',
        ],
      },
    ],
  },
  Team: {
    title: 'Team',
    paragraph: [
      'Work together to build a team full of goodwill, modesty and unity',
      'Talent standards: integrity and optimism, goodwill and dedication, modesty and unity, self-discipline and self-examination',
      'Rewarding the good and punishing the bad, rewarding diligence and punishing laziness, never let Lei Feng suffer',
      'Frontier Technology and Business Sharing Session',
      'Friday reading club, independent thinking',
    ],
    list: [
      {
        img: 'img/team/02.jpg',
      },
      {
        img: 'img/team/03.jpg',
      },
      {
        img: 'img/team/04.jpg',
      },
      {
        img: 'img/try/05.jpg',
      },
    ],
  },
  honor: {
    title: 'honor',
    list: [
      {
        img: 'img/honor/01.jpg',
      },
      {
        img: 'img/honor/02.jpg',
      },
    ],
  },
  Contact: {
    name: 'Qingdao DataJoint Information Consulting Co., Ltd.',
    address: '66 Huazhong Road, Qingdao, Shandong, P. R. China',
    contactName: 'Mr. Shao',
    phone: '18806485928',
    email: 'contact@datajoint.cn',
    miit: '鲁ICP备18040258号-1',
    recruitment: 'Recruitment JAVA backend, WEB front-end, click send resume',
  },
};

export default data;
