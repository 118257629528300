import $ from 'jquery';
import { Amap, Marker } from '@amap/amap-react';

export const Contact = ({
  data: { name, addressTitle, address, phoneTitle, contactName, phone, emailTitle, email, miit, recruitment },
}) => {

  async function onJump () {
    // 高德IP定位
    $.ajax({ // 需引入jQuery
        type: "POST",
        // 调用高德IP定位（需申请高德地图开发者账号，创建秘钥使用）
        url: "https://restapi.amap.com/v3/ip?key=d9af52421213c450603f1fc6e21d2a08",
        async: true, // 默认：异步请求
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        data: "",
        success: (data) => {
          if ("rectangle" in data && !isStrEmpty(data.rectangle)) { // data.rectangle：IP所在经纬度范围
            // data.rectangle："116.0119343,39.66127144;116.7829835,40.2164962"
            let locList = data.rectangle.split(";");
            let lngSum = 0, latSum = 0;
            for (let i in locList) {
              const locInfos = locList[i].split(",");
              lngSum = parseFloat(lngSum) + parseFloat(locInfos[0]);
              latSum = parseFloat(latSum) + parseFloat(locInfos[1]);
            }
            const currentLocation = {
              // 取返回经纬度的平均值，保留6位小数
              lng: parseFloat(lngSum / locList.length).toFixed(6),
              lat: parseFloat(latSum / locList.length).toFixed(6),
              startpoint: data.province + data.city,
            };
            const e = {
              lng: 120.294335,
              lat: 36.254971,
              endpoint: '青岛讯和数聚信息咨询有限公司'
            };
            window.location.href = `https://uri.amap.com/navigation?from=${currentLocation.lng},${currentLocation.lat},${currentLocation.startpoint}&to=${e.lng},${e.lat},${e.endpoint}&mode=car&callnative=1&coordinate=wgs84&src=mypage`;
          }
        },
        error: () => {
          const currentLocation = {
            lng: 120.404325,
            lat: 36.113943,
            startpoint: '山东省青岛市',
          };
          const e = {
            lng: 120.294335,
            lat: 36.254971,
            endpoint: '青岛讯和数聚信息咨询有限公司'
          };
          window.location.href = `https://uri.amap.com/navigation?from=${currentLocation.lng},${currentLocation.lat},${currentLocation.startpoint}&to=${e.lng},${e.lat},${e.endpoint}&mode=car&callnative=1&coordinate=wgs84&src=mypage`;
        }
    });
  };

function isStrEmpty(str) {
  return "undefined" === typeof str || str === null || str === "undefined" || str === "null" || str.toString().trim().length < 1;
}
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-5" style={{ height: '360px', color: '#000' }}>
            <Amap zoom={9} center={[120.294335, 36.254971]}>
              <Marker onClick={onJump} position={[120.294335, 36.254971]} />
            </Amap>
          </div>
          <div className="col-md-5 contact-info">
            <h3> {name}</h3>
            <div className="contact-item">
              <span>
                <img src="img/contact/address.png" alt="..." className="contact-img" />
                {address}
              </span>
            </div>

            <div className="contact-item">
              <span>
                <img src="img/contact/phone.png" alt="..." className="contact-img" />
                {contactName}:<a href={`tel:${phone}`}>{phone}</a>
              </span>
            </div>

            <div className="contact-item">
              <span>
                <img src="img/contact/mail.png" alt="..." className="contact-img" />
                <a href={`mailto:${email}`}>{email}</a>
              </span>
            </div>
            <div className="contact-item">
                <span>
                  <img src="img/contact/engage.png" alt="..." className="contact-img" />
                  <a href="https://www.aliwork.com/o/xhzp" target="_blank ">{recruitment}</a>
                </span>
            </div>
          </div>
          <div className="col-md-2 weChart">
            <img src="img/contact/weChart.png" alt="..." className="wechart-img" />
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <img src="img/logo.svg" alt="logo" />
          <span className="footer-name">© 2022 DataJoint, Co., Ltd.</span>
          <a className="footer-link" href="https://beian.miit.gov.cn">
            {miit}
          </a>
        </div>
      </div>
    </div>
  );
};
