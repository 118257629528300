import React from 'react';
import ReactDOM from 'react-dom';
import { config as AmapReactConfig } from '@amap/amap-react';
import './index.css';
import App from './App';

AmapReactConfig.version = '2.0'; // 默认2.0，这里可以不修改
AmapReactConfig.key = 'a02d72d2bcc04d21211fdb091d8da11c';
AmapReactConfig.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
