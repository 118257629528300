import Slider from "react-slick";
import { isMobile } from '../App';

export const Team = ({ data: { title, paragraph, list } }) => {
  const minSliderDisplay = 3;
  const slidesToShow = list.length >= minSliderDisplay ? minSliderDisplay : list.length;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile() ? 1 : slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div id="team" className="text-center" style={{ marginBottom: '30px' }}>
      <div className="container">
        <div id="team-section-title" className="section-title">
          <h2>{title}</h2>

          <div className="about-text">
            <ul>
              {paragraph.map((d, i) => (
                <li key={`${d}-${i}`}>{d}</li>
              ))}
            </ul>
          </div>
        </div>

        <div id="row">
          <Slider {...settings}>
            {list.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6 team">
              <div className="thumbnail">
                <img src={d.img} alt="..." className="team-img" style={{ width: '100%', objectFit: 'cover' }} />
              </div>
            </div>
          ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
