export const About = ({ data: { title1, title2, paragraph, list } }) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 img-container">
            <div className="img-box">
              <img src="img/about.jpg" className="img-responsive" alt="" />
            </div>
            
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <div id="about-title" className="about-title">
                <h2>{title1}</h2>
              </div>
              

              <p style={{ textIndent: '2em' }}>{paragraph}</p>

              <h3>{title2}</h3>

              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    {list.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
