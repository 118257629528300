export const Navigation = ({ data: { toggleTitle, title, list, language }, onLanChange }) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container" style={{ padding: 0 }}>
        <div className="navbar-header" style={{ margin: 0 }}>
          <button
            id="menuButton"
            type="button"
            className="navbar-toggle collapsed menu"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only menu">{toggleTitle}</span>
            <span className="icon-bar menu"></span>
            <span className="icon-bar menu"></span>
            <span className="icon-bar menu"></span>
          </button>
          <img src="img/logo.svg" className="menu_logo" alt="logo" />
          <a className="navbar-brand page-scroll" href="#page-top">
            {title}
          </a>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            {list.map(i => {
              return (
                <li>
                  <a href={i.href} className="page-scroll">
                    {i.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="lang_switch_btn" onClick={onLanChange}>
          {language}
        </div>
      </div>
    </nav>
  );
};
