export const Services = ({ data: { title, list } }) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div id="services-section-title" className="section-title">
          <h2>{title}</h2>
        </div>

        <div className="row">
          {list.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4">
              <div style={{ marginBottom: 4 }}>
                <img src={d.img} /> <span>{d.name}</span>
              </div>

              <div className="service-desc">
                <p style={{ textAlign: 'justify', textIndent: '2em' }}>{d.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
