import Slider from "react-slick";
import { isMobile } from '../App';

export const Honor = ({ data: { title, list } }) => {
  const minSliderDisplay = 3;
  const slidesToShow = list.length >= minSliderDisplay ? minSliderDisplay : list.length;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile() ? 1 : slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
  };

  return (
    <div id="honor" className="text-center" style={{ marginBottom: '30px' }}>
      <div className="container">
        <div id="honor-section-title" className="section-title">
          <h2>{title}</h2>
        </div>

        <div id="row">
          <Slider {...settings}>
            {list.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6 team">
                <div className="thumbnail">
                  <div className="light" />
                  <img src={d.img} alt="..." className="team-img" style={{ maxHeight: 209 }} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
